import {
  createChart,
  IChartApi,
  DeepPartial,
  ChartOptions,
  ColorType,
  ISeriesApi,
  Coordinate
} from 'lightweight-charts';

import { useEffect, useRef } from 'react';
import { useTheme } from '@/context/theme-provider.tsx';
import Loading from '@/lib/ui/loading.tsx';
import EmptyState from '@/lib/ui/empty-state.tsx';

const chartHeight = 275;

const COLORS = {
  DEFAULT: '39, 130, 216'
};

export type AreaChartItemProps = { time: string; value: number; date: string };

export type AreaChartProps = {
  name: string;
  loading?: boolean;
  data: Array<AreaChartItemProps>;
};

export default function AreaChart({ name, data, loading }: AreaChartProps) {
  const color = COLORS.DEFAULT;
  const { theme } = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartTooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let chart: IChartApi | null = null;
    let series: ISeriesApi<'Area'> | null = null;

    if (chartContainerRef.current) {
      const chartOptions: DeepPartial<ChartOptions> = {
        width: chartContainerRef.current.clientWidth,
        height: chartHeight,
        layout: {
          textColor: theme === 'dark' ? '#ffffff' : '#000000',
          background: { type: ColorType.Solid, color: 'transparent' }
        }
      };

      chart = createChart(chartContainerRef.current, chartOptions);
      chart.applyOptions({
        leftPriceScale: {
          visible: true,
          borderVisible: false
        },
        rightPriceScale: {
          visible: false
        },
        timeScale: {
          borderVisible: false
        },
        crosshair: {
          mode: 0 // Disabled to avoid confusion with the custom tooltip handling
        },
        grid: {
          vertLines: {
            visible: false
          },
          horzLines: {
            visible: false
          }
        }
      });

      series = chart.addAreaSeries({
        topColor: `rgba(${color}, 0.4)`,
        bottomColor: `rgba(${color}, 0.0)`,
        lineColor: `rgba(${color}, 1)`,
        lineWidth: 2,
        crosshairMarkerRadius: 5, // Ajusta el radio del marcador
        crosshairMarkerBorderColor: `rgba(${color}, 1)`,
        crosshairMarkerBackgroundColor: 'white'
      });

      series.setData(
        data.sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      );

      if (chartTooltipRef.current) {
        const toolTipWidth = 96;
        chartTooltipRef.current.style.width = `${toolTipWidth}px`;
        chartTooltipRef.current.style.background = `hsl(var(--background) / 0.5)`;
        chartTooltipRef.current.style.color = 'currentColor';
        chartTooltipRef.current.style.border = `1px solid rgba(${color}, 1)`;

        chart.subscribeCrosshairMove((param) => {
          if (
            chartTooltipRef.current &&
            chartContainerRef?.current &&
            series &&
            chart
          ) {
            if (
              param.point === undefined ||
              !param.time ||
              param.point.x < 0 ||
              param.point.x > chartContainerRef?.current.clientWidth ||
              param.point.y < 0 ||
              param.point.y > chartContainerRef?.current?.clientHeight
            ) {
              chartTooltipRef.current.style.display = 'none';
            } else {
              // time will be in the same format that we supplied to setData.
              // thus it will be YYYY-MM-DD
              const dateStr = param.time;
              chartTooltipRef.current.style.display = 'block';
              const record = param.seriesData.get(series) as any;
              const price =
                record.value !== undefined ? record.value : record?.close;
              chartTooltipRef.current.innerHTML = `<div style="color: rgba(${color}, 1)">⬤ ${name}.</div><div style="font-size: 24px; margin: 4px 0px; color: hsl(var(--foreground))">
            ${Math.round(100 * price) / 100}
            </div><div style="color: hsl(var(--foreground))">
            ${dateStr}
            </div>`;

              let left: Coordinate | number = param.point.x; // relative to timeScale
              const timeScaleWidth = chart.timeScale().width();
              const priceScaleWidth = chart.priceScale('left').width();
              const halfTooltipWidth = toolTipWidth / 2;
              left += priceScaleWidth - halfTooltipWidth;
              left = Math.min(
                left,
                priceScaleWidth + timeScaleWidth - toolTipWidth
              );
              left = Math.max(left, priceScaleWidth);

              chartTooltipRef.current.style.left = left + 'px';
              chartTooltipRef.current.style.top = 0 + 'px';
            }
          }
        });

        chart.timeScale().fitContent();
      }
    }

    return () => {
      if (chart) {
        chart.remove();
        chart = null;
      }
    };
  }, [color, name, theme, data]);

  if (loading) {
    return <Loading className="min-h-[300px]" />;
  }

  if (!data?.length) {
    return <EmptyState title="Empty Data." />;
  }

  return (
    <>
      <div ref={chartContainerRef} className="h-72 w-full relative">
        <div
          ref={chartTooltipRef}
          style={{
            height: chartHeight,
            position: 'absolute',
            display: 'none',
            padding: 8,
            boxSizing: 'border-box',
            fontSize: '12px',
            textAlign: 'left',
            zIndex: '1000',
            top: 12,
            left: 12,
            pointerEvents: 'none',
            borderRadius: '4px 4px 0px 0px',
            borderBottom: 'none',
            boxShadow: '0 2px 5px 0 rgba(117, 134, 150, 0.45)',
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif"
          }}
        >
          {/* Tooltip content managed by JavaScript */}
        </div>
      </div>
    </>
  );
}
