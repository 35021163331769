import GenericCard from '@/lib/ui/generic-card.tsx';
import { useGetTotalCustomers } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import Loading from '@/lib/ui/loading.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function TotalCustomers() {
  const { t } = useTranslation();
  const { loading, data } = useGetTotalCustomers();

  return (
    <GenericCard
      variant="secondary"
      title={t('total_customers')}
      classNameBody="flex items-center gap-2 text-4xl"
      className="min-h-[170px] w-full"
      subTitle={
        <>
          {loading ? <Loading className="min-h-[auto]" /> : <span>
            {data?.space?.userRegisteredCount || 0}
            {/*<span className="text-lg font-light text-default-500">Clients</span>*/}
          </span>}
          {/*<span className="text-success font-medium text-lg">*/}
          {/*  +10 this month*/}
          {/*</span>*/}
        </>
      }
    />
  );
}
