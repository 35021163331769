import * as React from 'react';
import { cn } from '@/lib/utils.ts';
import { createElement } from 'react';

const sizes = {
  xxl: 'text-3xl md:text-3xl lg:text-4xl',
  xl: 'text-2xl lg:text-3xl',
  lg: 'text-xl lg:text-2xl',
  md: 'text-lg lg:text-xl',
  sm: 'text-md lg:text-lg',
  xs: 'text-sm lg:text-md'
};

const components = {
  xxl: 'h1',
  xl: 'h2',
  lg: 'h3',
  md: 'h4',
  sm: 'h5',
  xs: 'p'
};

export default function Title({
  className,
  size = 'xxl',
  ...props
}: React.ButtonHTMLAttributes<HTMLParagraphElement> & {
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
}) {
  return (
    <>
      {createElement(components[size] || 'h1', {
        ...props,
        className: cn(
          `mb-4 font-extrabold leading-none tracking-tight text-gray-900 dark:text-white`,
          sizes[size ?? 'xxl'],
          className
        )
      })}
    </>
  );
}
