import GenericCard from '@/lib/ui/generic-card.tsx';
import { useGetTotalRevenue } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import Loading from '@/lib/ui/loading.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import dayjs from 'dayjs';

export default function TotalRevenue() {
  const { t } = useTranslation();
  const { total, loading } = useGetTotalRevenue();

  return (
    <GenericCard
      variant="secondary"
      title={`${t('total_revenue')} (${dayjs().format('MMM YYYY')})`}
      className="min-h-[170px] w-full"
      classNameBody="text-4xl"
      subTitle={
        loading ? (
          <Loading className="min-h-[auto]" />
        ) : (
          <span>
            ${total?.toFixed?.(2)}
            <span className="text-lg font-light text-default-500">USD</span>
          </span>
        )
      }
    />
  );
}
