import * as React from 'react';

import {
  Table as NextTable,
  TableProps as NextTableProps,
  TableHeader,
  TableColumn as NextTableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
  Pagination,
  PaginationProps
} from '@nextui-org/react';
import { useState } from 'react';
import { ColumnSize } from '@react-types/table';

import EmptyState from '@/lib/ui/empty-state.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';

export type RenderCellParams<T extends object> = {
  row: T;
  columnIndex: number;
  rowIndex: number;
  isHovered: boolean;
};

export type TableColumn<T extends object> = Array<{
  field: keyof T | string;
  headerName: string;
  width?: string | number;
  height?: string | number;
  renderCell?: (
    params: RenderCellParams<T>
  ) => string | number | null | undefined | React.ReactElement;
}>;

export type TableProps<T extends object> = NextTableProps & {
  columns: TableColumn<T>;
  rows: T[];
  isLoading?: boolean;
  getRowId?: (row: T, index: number) => string | number;
  pagination?: Pick<PaginationProps, 'total' | 'page' | 'onChange'>;
};

export function Table<T extends object>({
  className,
  columns,
  rows,
  getRowId,
  isLoading,
  pagination,
  ...props
}: TableProps<T>) {
  const { t } = useTranslation();
  const [isHoveredId, setIsHoveredId] = useState<null | string>(null);

  return (
    <NextTable
      color="primary"
      className={className}
      classNames={{
        wrapper: 'p-0 shadow-none overflow-y-hidden',
      }}
      bottomContent={
        !!pagination && (
          <div className="flex w-full justify-end">
            <Pagination variant="light" {...pagination} />
          </div>
        )
      }
      {...props}
    >
      <TableHeader>
        {columns.map((column, index) => {
          return (
            <NextTableColumn
              width={column?.width as ColumnSize}
              key={`${column?.field?.toString?.() ?? '-'}.${index}`}
            >
              {column.headerName ?? column.field}
            </NextTableColumn>
          );
        })}
      </TableHeader>
      <TableBody
        loadingContent={
          <div className="rounded w-full h-full flex items-center justify-center bg-default-100/30">
            <Spinner color="primary" />
          </div>
        }
        loadingState={isLoading ? 'loading' : 'idle'}
        emptyContent={
          <EmptyState title={t('empty_table')} className="max-w-[200px]" />
        }
      >
        {rows.map((row, rowIndex) => {
          const id = (row as { id: string })?.id ?? getRowId?.(row, rowIndex);

          if (id === '' || id === null || id === undefined) {
            throw new Error('ID is required. Please use getRowId function.');
          }

          const isHovered = isHoveredId === id;

          return (
            <TableRow
              key={id}
              onMouseEnter={() => setIsHoveredId(id)}
              onMouseLeave={() => setIsHoveredId(null)}
            >
              {columns.map((column, columnIndex) => (
                <TableCell
                  height={column?.height}
                  width={column?.width}
                  key={`${id}-${column?.field?.toString?.() ?? columnIndex}`}
                >
                  {column?.renderCell
                    ? column.renderCell({
                        row,
                        columnIndex,
                        rowIndex,
                        isHovered
                      })
                    : (row?.[column?.field as keyof T] as string)}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </NextTable>
  );
}
