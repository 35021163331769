import { Table } from '@/lib/ui/table.tsx';
import { useGetClientsList } from '@/app/app/clients/store/query/clients.query.ts';
import { useColumnSettings } from '@/app/app/clients/components/ClientList/settings/useSettingsTable.tsx';
import { UserFragmentFragment } from '@/__generated__/graphql.ts';

export default function ClientList() {
  const { data, loading } = useGetClientsList();
  const columns = useColumnSettings();

  return (
    <Table
      columns={columns}
      isLoading={loading}
      rows={(data?.space?.userRegistered || []) as UserFragmentFragment[]}
    />
  );
}
