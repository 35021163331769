import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { cn } from '@/lib/utils.ts';
import AreaChart from '@/lib/ui/charts/area-chart.tsx';
import Text from '@/lib/ui/text.tsx';
import dayjs from 'dayjs';
import { useGetActivity } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export function ActivityGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { activity, loading } = useGetActivity();

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <div className="flex gap-2 justify-between items-center">
            <Text className="text-3xl font-bold">{t('activity.title')}</Text>
            <Text className="text-xl">{dayjs().format('MMM, YYYY')}</Text>
          </div>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <AreaChart loading={loading} name={t('assistants')} data={activity || []} />
      </CardBody>
    </Card>
  );
}
