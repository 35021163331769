import { Card, CardBody, Chip } from '@nextui-org/react';
import {
  CreditFragmentFragment,
  PlanFragmentFragment
} from '@/__generated__/graphql.ts';
import Text from '@/lib/ui/text.tsx';
import { cn, formatDate } from '@/lib/utils.ts';
import { CREDIT_STATUS_COLOR } from '@/app/app/clients/common/colors/credit.colors.ts';
import { CREDIT_STATUS_ENUM } from '@/app/app/clients/common/enums/credit.enum.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function SubscriptionCard({
  creditBuy,
  expiredAt,
  openAt,
  status,
  plan
}: Omit<CreditFragmentFragment, 'plan'> & { plan: PlanFragmentFragment }) {
  const { t } = useTranslation();

  return (
    <Card
      className={cn(
        'shadow-none inline-flex items-center justify-between flex-row-reverse',
        'border-1 border-default rounded-lg gap-4'
      )}
    >
      <CardBody>
        <div className="flex gap-2 justify-between items-center">
          <Text className="relative text-foreground select-none text-medium transition-colors motion-reduce:transition-none">
            {plan?.name} - {creditBuy}{' '}
            {t((creditBuy ?? 0) <= 1 ? 'class' : 'classes')}
          </Text>

          <Chip
            size="sm"
            radius="sm"
            variant="flat"
            color={CREDIT_STATUS_COLOR[status as CREDIT_STATUS_ENUM]}
          >
            {t(status as LanguageKeys, status)}
          </Chip>
        </div>
        <Text className="text-small text-foreground opacity-70">
          {formatDate(openAt)} - {formatDate(expiredAt)}
        </Text>
      </CardBody>
    </Card>
  );
}
