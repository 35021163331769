import { EyeIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Avatar, Card, CardBody, Tooltip } from '@nextui-org/react';

import Text from '@/lib/ui/text.tsx';
import { Table } from '@/lib/ui/table.tsx';
import ShortId from '@/lib/ui/short-id.tsx';
import DateCell from '@/lib/ui/date-cell.tsx';
import { useTranslation } from '@/hooks/useTranslation.ts';
import { CLIENT_ROUTES } from '@/app/app/clients/common/routes.ts';
import { useGetUpcomingRenewals } from '@/app/app/dashboard/store/query/dashboard.query.ts';
// import { CREDIT_STATUS_ENUM } from '@/app/app/clients/common/enums/credit.enum.ts';
// import { CREDIT_STATUS_COLOR } from '@/app/app/clients/common/colors/credit.colors.ts';

export default function UpcomingRenewals({
  className
}: {
  className?: string;
}) {
  const { t } = useTranslation();
  const { renewals, loading } = useGetUpcomingRenewals();

  return (
    <Card className={className}>
      <CardBody>
        <div className="flex flex-col gap-4 p-4">
          <Text className="text-3xl font-bold">{t('upcoming_renewals')}</Text>
          <Table
            isLoading={loading}
            rows={renewals || []}
            columns={[
              {
                field: 'id',
                headerName: 'ID',
                renderCell({ row }) {
                  return <ShortId slice={3}>{row.user?.id || ''}</ShortId>;
                }
              },
              {
                field: 'name',
                headerName: t('name'),
                renderCell({ row }) {
                  return (
                    <div className="flex gap-2 items-center">
                      <Avatar
                        src={row?.user?.avatar?.url}
                        name={row?.user?.fullName}
                      />

                      <Text>{row.user?.fullName}</Text>
                    </div>
                  );
                }
              },
              {
                field: 'plan',
                headerName: 'Plan',
                renderCell({ row }) {
                  return <Text>{row.plan?.name}</Text>;
                }
              },
              {
                field: 'expiredAt',
                headerName: t('expire'),
                renderCell({ row }) {
                  return <DateCell date={row?.expiredAt} />;
                }
              },
              // {
              //   field: 'status',
              //   headerName: t('status'),
              //   renderCell({ row }) {
              //     const statusColor =
              //       CREDIT_STATUS_COLOR?.[row?.status as CREDIT_STATUS_ENUM];
              //
              //     return (
              //       <Chip size="sm" variant="flat" color={statusColor}>
              //         {t(`credit_status.${row?.status}` as LanguageKeys)}
              //       </Chip>
              //     );
              //   }
              // },
              {
                field: 'actions',
                headerName: t('actions'),
                renderCell({ row }) {
                  return (
                    <Tooltip content={t('view_more')}>
                      <span>
                        <Link to={CLIENT_ROUTES.CLIENT_DETAILS(row.user?.id)}>
                          <EyeIcon className="h-4 w-4" />
                        </Link>
                      </span>
                    </Tooltip>
                  );
                }
              }
            ]}
          />
        </div>
      </CardBody>
    </Card>
  );
}
