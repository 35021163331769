import { Card, CardBody } from '@nextui-org/react';
import Text from '@/lib/ui/text.tsx';
import { ReactNode } from 'react';
import { cn } from '@/lib/utils.ts';

export default function GenericCard({
  icon,
  footer,
  title,
  subTitle,
  className,
  classNameBody,
  variant = 'primary'
}: {
  title: ReactNode;
  subTitle?: ReactNode;
  footer?: ReactNode;
  className?: string;
  classNameBody?: string;
  icon?: ReactNode;
  variant?: 'primary' | 'secondary';
}) {
  if (variant === 'primary') {
    return (
      <Card className={cn('p-2', className)}>
        <CardBody className={cn('flex flex-col text-current')}>
          {icon}
          <Text
            className={cn('font-bold text-5xl text-current', classNameBody)}
          >
            {title}
            <span className="text-sm text-current">{subTitle}</span>
          </Text>
          <Text className="text-sm text-current">{footer}</Text>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className={cn('p-2', className)}>
      <CardBody
        className={cn('flex flex-col justify-center gap-5 text-current')}
      >
        {icon}
        <Text className={cn('text-current text-lg')}>{title}</Text>
        <div>
          <Text
            className={cn('font-bold text-5xl text-current', classNameBody)}
          >
            {subTitle}
          </Text>
          <Text className="text-lg text-current">{footer}</Text>
        </div>
      </CardBody>
    </Card>
  );
}
