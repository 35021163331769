import { toast } from 'sonner';
import { useMutation } from '@apollo/client';
import * as MUTATION from '@/app/app/clients/store/mutation/graphql/clients.graphql';
import * as QUERY from '@/app/app/clients/store/query/graphql/clients.graphql';
import { useAuth } from '@/app/auth/_context';
import { useGetClientsList } from '@/app/app/clients/store/query/clients.query.ts';
import {
  CreateClientMutationVariables,
  CreateCreditMutationVariables,
  PlanFragmentFragment,
  UpdateClientMutationVariables,
  UpdateCreditMutationVariables
} from '@/__generated__/graphql.ts';

export function useNewClient() {
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id;
  const { data: clients } = useGetClientsList();

  const [onFunction, restMutation] = useMutation(MUTATION.CREATE_CLIENT_QUERY, {
    refetchQueries: [
      {
        query: QUERY.GET_CLIENTS_QUERY,
        variables: {
          spaceId
        }
      }
    ]
  });

  const onNewClient = async ({ data }: CreateClientMutationVariables) => {
    const isExist = clients?.space?.userRegistered?.find?.(
      (item: any) => item?.email === data?.email
    );

    if (isExist) {
      toast.error('Client already exists', {
        description: 'Please select a other email'
      });
      throw new Error('Client already exists');
    }
    return onFunction({
      variables: {
        data: {
          ...data,
          spaceRegistered: {
            connect: [
              {
                id: spaceId as string
              }
            ]
          }
        }
      }
    });
  };

  return { onNewClient, ...restMutation };
}

export function useUpdateClient(clientId: string) {
  const { data: clients } = useGetClientsList();
  const { activeSpace } = useAuth();
  const spaceId = activeSpace?.space?.id;

  const [onFunction, restMutation] = useMutation(MUTATION.UPDATE_CLIENT_QUERY, {
    onCompleted({ updateUser }) {
      if (updateUser) {
        toast('Success', {
          description: "Client's has been changed"
        });
      } else {
        toast('Failed', {
          description: "Client's has been changed"
        });
      }
    },
    onError(e) {
      toast('Failed', {
        description: e?.message || 'Error updating client'
      });
    },
    refetchQueries: [
      {
        query: QUERY.GET_CLIENTS_QUERY,
        variables: {
          spaceId
        }
      },
      {
        query: QUERY.GET_CLIENT_QUERY,
        variables: {
          clientId
        }
      }
    ]
  });

  const onUpdateUser = async ({
    data
  }: Omit<UpdateClientMutationVariables, 'clientId'>) => {
    const isExist = clients?.space?.userRegistered?.find?.(
      (item: any) => item?.email === data?.email && item?.id !== clientId
    );

    if (isExist) {
      toast.error('Client already exists', {
        description: 'Please select a other email'
      });
      throw new Error('Client already exists');
    }

    if (!clientId) {
      throw new Error('Client ID is required');
    }
    return onFunction({
      variables: {
        clientId,
        data
      }
    });
  };

  return { onUpdateUser, ...restMutation };
}

export function useAddOrRemoveBadgeClient(clientId: string) {
  const { onUpdateUser, ...rest } = useUpdateClient(clientId);

  const onUpdateBadgeClient = async ({
    badges
  }: Pick<UpdateClientMutationVariables['data'], 'badges'>) => {
    if (!clientId) {
      throw new Error('Client ID is required');
    }
    return onUpdateUser({
      data: {
        badges
      }
    });
  };

  return { onUpdateBadgeClient, ...rest };
}

export function useAddNewSubscription({ clientId }: { clientId: string }) {
  const { activeSpace, user } = useAuth();
  const spaceId = activeSpace?.space?.id;

  const [onFunction, restMutation] = useMutation(
    MUTATION.CREATE_SUBSCRIPTION_QUERY,
    {
      refetchQueries: [
        {
          query: QUERY.GET_CLIENT_QUERY,
          variables: {
            clientId
          }
        },
        {
          query: QUERY.GET_SUBSCRIPTION_BY_CLIENT_QUERY,
          variables: {
            clientId
          }
        }
      ]
    }
  );

  const onNewSubscription = async ({
    data,
    plan
  }: CreateCreditMutationVariables & {
    plan?: PlanFragmentFragment | null;
  }) => {
    try {
      await onFunction({
        variables: {
          data: {
            ...data,
            space: {
              connect: {
                id: spaceId
              }
            },
            payment: {
              create: {
                name: ['Plan', plan?.name].join(' '),
                note: [`Plan ${plan?.name}`, `${data?.creditBuy} classes`].join(
                  ', '
                ),
                quantity: 1,
                method: data?.paymentMethod,
                amount: data?.totalPrice,
                user: {
                  connect: {
                    id: user?.item?.id
                  }
                },
                space: {
                  connect: {
                    id: spaceId
                  }
                }
              }
            }
          }
        }
      });
    } catch (e) {
      console.log((e as Error)?.message || 'Error creating subscription');
      toast.error((e as Error)?.message || 'Error creating subscription');
      throw e;
    }
  };

  return { onNewSubscription, ...restMutation };
}

export function useEditSubscription({ clientId }: { clientId: string }) {
  const [onFunction, restMutation] = useMutation(
    MUTATION.UPDATE_SUBSCRIPTION_QUERY,
    {
      refetchQueries: [
        {
          query: QUERY.GET_CLIENT_QUERY,
          variables: {
            clientId
          }
        },
        {
          query: QUERY.GET_SUBSCRIPTION_BY_CLIENT_QUERY,
          variables: {
            clientId
          }
        }
      ]
    }
  );

  const onEditSubscription = async ({
    data,
    creditId
  }: UpdateCreditMutationVariables) => {
    try {
      await onFunction({
        variables: {
          creditId,
          data
        }
      });
    } catch (e) {
      console.log((e as Error)?.message || 'Error editing subscription');
      toast.error((e as Error)?.message || 'Error editing subscription');
      throw e;
    }
  };

  return { onEditSubscription, ...restMutation };
}
