import Container from '@/lib/ui/container.tsx';
import Greetings from '@/app/app/dashboard/components/organisms/Greeting';
import TotalRevenue from '@/app/app/dashboard/components/organisms/TotalRevenue/TotalRevenue.tsx';
import {
  ActivityGraph,
  PaymentMethodGraph
} from '@/app/app/dashboard/components/organisms/Graphs';
import Grid from '@/lib/ui/grid';
import TodaySessions from '@/app/app/dashboard/components/organisms/TodaySessions';
import CustomerService from '@/app/app/dashboard/components/organisms/CustomerService';
import UpcomingRenewals from '@/app/app/dashboard/components/organisms/UpcomingRenewals';
import TotalCustomers from '@/app/app/dashboard/components/organisms/TotalCustomers';
import { useAuth } from '@/app/auth/_context';

export default function Dashboard() {
  const { activeSpace } = useAuth();

  const isOwner = activeSpace?.isOwner;

  return (
    <Container size="xl" className="flex flex-col gap-12">
      <Grid gutter={[24, 24]}>
        {/* left column or mobile second column */}
        <Grid.Item xs={24} lg={16} className="order-2 lg:order-1">
          <div className="flex flex-col gap-6">
            <Greetings className="w-full hidden lg:block" />
            {isOwner && <ActivityGraph className="w-full" />}
            {isOwner && <PaymentMethodGraph />}
            <UpcomingRenewals />
            <div className="h-[100px]" />
          </div>
        </Grid.Item>
        {/* right column or mobile first column */}
        <Grid.Item xs={24} lg={8} className="order-1 lg:order-2">
          <div className="flex flex-col gap-6">
            <Greetings className="w-full block lg:hidden" />
            <CustomerService className="lg:hidden" />
            {isOwner && <TotalRevenue />}
            <TotalCustomers />
            <TodaySessions key="today-sessions" />
            <CustomerService className="hidden lg:block" />
          </div>
        </Grid.Item>
      </Grid>
    </Container>
  );
}
