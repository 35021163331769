import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import BigNumber from 'bignumber.js';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDateComplete(
  date: dayjs.ConfigType,
  opts?: {
    showEmptyDate?: boolean;
    format?: 'date' | 'datetime';
  }
) {
  const { showEmptyDate, format } = {
    showEmptyDate: true,
    format: 'date',
    ...opts
  };

  if (!date && !showEmptyDate) {
    return '-';
  }
  return dayjs(date).format(
    format === 'date' ? 'DD MMMM YYYY' : 'DD MMMM YYYY hh:mm A'
  );
}

export function formatDate(date: dayjs.ConfigType, showEmptyDate = true) {
  if (!date && !showEmptyDate) {
    return '-';
  }
  return dayjs(date).format('DD/MM/YYYY');
}

export function formatTime(date: dayjs.ConfigType) {
  return dayjs(date).format('hh:mm A');
}

export type FormatMoneyOptions = {
  symbol?: '$' | 'usd' | 'none';
};

export function formatMoney(
  value: BigNumber.Value,
  options?: FormatMoneyOptions
) {
  const opts: Required<FormatMoneyOptions> = {
    symbol: '$',
    ...options
  };
  const amount = new BigNumber(value).dp(2);
  if (amount.isNaN()) return '-';

  if (opts.symbol === 'none') return amount.toString();
  return [opts.symbol.toUpperCase(), amount?.toString()].join(
    opts.symbol === '$' ? '' : ' '
  );
}
