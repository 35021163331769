import Title from '@/lib/ui/title.tsx';
import Text from '@/lib/ui/text.tsx';
import { useAuth } from '@/app/auth/_context';
import { Card, CardBody } from '@nextui-org/react';
import { useTranslation } from '@/hooks/useTranslation.ts';

export default function Greetings({ className }: { className?: string }) {
  const { user } = useAuth();
  const { t } = useTranslation();
  // return (
  //   <Card
  //     style={{ overflow: 'inherit' }}
  //     className="bg-primary/80 text-white mt-[50px] relative p-4 md:h-52 block"
  //   >
  //     <div className="relative md:absolute z-40 bottom-0 h-64 pl-10">
  //       <img
  //         alt=""
  //         className="h-full mx-auto md:mx-0"
  //         src="/images/greeting.png"
  //         style={{
  //           filter: 'drop-shadow(#b78c6c 0px 20px 35px)'
  //         }}
  //       />
  //     </div>
  //     <CardBody className="text-center relative pt-7 md:pt-0 md:pl-[300px] md:text-left">
  //       <Title className="text-white"><span className="text-black">Jogo</span> Management!</Title>
  //       <Text>Jogo te ayudara administrar tus clientes y tu negocio, puedes ver algunos datos mensuales importantes.</Text>
  //     </CardBody>
  //   </Card>
  // );
  return (
    <div className={className}>
      <Card
        style={{ overflow: 'inherit' }}
        className="bg-gradient-to-r from-cyan-500 to-blue-500 bg-primary/80 text-white relative p-4 md:h-[170px] block"
      >
        <CardBody className="relative">
          <Title className="text-white">
            {t('dashboard.banner_title', null, { name: user?.item?.firstName })}
          </Title>
          <Text>{t('dashboard.banner_subtitle')}</Text>
        </CardBody>
      </Card>
    </div>
  );
}
