import { Card, CardBody, CardHeader } from '@nextui-org/react';
import { cn } from '@/lib/utils.ts';
import Text from '@/lib/ui/text.tsx';
import dayjs from 'dayjs';
import PieChart from '@/lib/ui/charts/pie-chart.tsx';
import { useGetPaymentMethod } from '@/app/app/dashboard/store/query/dashboard.query.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

export function PaymentMethodGraph({ className }: { className?: string }) {
  const { t } = useTranslation();
  const { options } = useGetPaymentMethod();

  return (
    <Card className={cn('relative block', className)}>
      <CardBody>
        <CardHeader className="flex-col items-start">
          <div className="flex gap-2 justify-between items-center">
            <Text className="text-3xl font-bold">{t('income')}</Text>
            <Text className="text-xl">{dayjs().format('MMM, YYYY')}</Text>
          </div>
          <Text className="mb-4">{t('activity.sub_title')}</Text>
        </CardHeader>

        <PieChart chartWrapperClass="max-w-[300px]" data={options} />
      </CardBody>
    </Card>
  );
}
