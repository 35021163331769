import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';

import { useAuth } from '@/app/auth/_context';
import {
  ALL_ACTIVITY_QUERY,
  GET_UPCOMING_RENEWALS_QUERY,
  GET_VALID_RENEWALS_QUERY,
  TODAY_SESSIONS_QUERY,
  TOTAL_CUSTOMER_QUERY,
  TOTAL_REVENUE_QUERY
} from '@/app/app/dashboard/store/query/graphql/dashboard.graphql.ts';
import { useMemo } from 'react';
import { PieChartType } from '@/lib/ui/charts/pie-chart.tsx';
import { PaymentMethodEnum } from '@/app/app/clients/components/PaymentMethodSelect/PaymentMethodSelect.tsx';
import { AreaChartItemProps } from '@/lib/ui/charts/area-chart.tsx';
import {
  GetUpcomingRenewalsQuery,
  UserFragmentFragment
} from '@/__generated__/graphql.ts';
import { useTranslation } from '@/hooks/useTranslation.ts';

const now = dayjs();

export function useGetTodaySessionsQuery() {
  const { activeSpace } = useAuth();

  const query = useQuery(TODAY_SESSIONS_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      day: now.toISOString(),
      spaceId: activeSpace?.space?.id as string
    }
  });

  return {
    ...query,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

export function useGetTotalCustomers() {
  const { activeSpace } = useAuth();

  const query = useQuery(TOTAL_CUSTOMER_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });

  return {
    ...query,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

export function useGetTotalRevenue() {
  const nowInStartDate = dayjs(now).utc().startOf('month');
  const { activeSpace } = useAuth();

  const query = useQuery(TOTAL_REVENUE_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      date: nowInStartDate.toISOString(),
      spaceId: activeSpace?.space?.id as string
    }
  });

  return {
    ...query,
    total:
      query?.data?.credits?.reduce?.(
        (acc: number, credit: any) => acc + credit.totalPrice,
        0
      ) ?? 0,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

const colors: Record<PaymentMethodEnum, string> = {
  [PaymentMethodEnum.CASH]: '#32CD32',
  [PaymentMethodEnum.BITCOIN]: '#FFC700',
  [PaymentMethodEnum.STRIPE]: '#7B95F1',
  [PaymentMethodEnum.TARGET]: '#FF495C',
  [PaymentMethodEnum.TRANSFER]: '#0087ff',
  [PaymentMethodEnum.PAYMENT_LINK]: '#FB7185'
};

export function useGetPaymentMethod() {
  const query = useGetTotalRevenue();
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (query?.data?.credits) {
      const records: PieChartType['data'] = [
        {
          key: PaymentMethodEnum.BITCOIN,
          title: t(
            `methods_payment.${PaymentMethodEnum.BITCOIN}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.BITCOIN],
          value: 0
        },
        {
          key: PaymentMethodEnum.TARGET,
          title: t(
            `methods_payment.${PaymentMethodEnum.TARGET}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.TARGET],
          value: 0
        },
        {
          key: PaymentMethodEnum.TRANSFER,
          title: t(
            `methods_payment.${PaymentMethodEnum.TRANSFER}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.TRANSFER],
          value: 0
        },
        {
          key: PaymentMethodEnum.STRIPE,
          title: t(
            `methods_payment.${PaymentMethodEnum.STRIPE}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.STRIPE],
          value: 0
        },
        {
          key: PaymentMethodEnum.PAYMENT_LINK,
          title: t(
            `methods_payment.${PaymentMethodEnum.PAYMENT_LINK}` as LanguageKeys
          ),
          color: colors[PaymentMethodEnum.PAYMENT_LINK],
          value: 0
        },
        {
          key: PaymentMethodEnum.CASH,
          title: t(`methods_payment.${PaymentMethodEnum.CASH}` as LanguageKeys),
          color: colors[PaymentMethodEnum.CASH],
          value: 0
        }
      ];

      query.data.credits.forEach((credit) => {
        const findIndex = records.findIndex(
          (item) => item.key === credit.paymentMethod
        );
        if (findIndex !== -1) {
          records[findIndex].value += credit.totalPrice || 0;
        } else {
          records.push({
            key: credit.paymentMethod as PaymentMethodEnum,
            color: colors[credit.paymentMethod as PaymentMethodEnum], // Turquesa brillante
            title: t(`methods_payment.${credit.paymentMethod}` as LanguageKeys) as string,
            value: credit.totalPrice || 0
          });
        }
      });

      return records;
    }

    return [];
  }, [t, query?.data]);

  return {
    ...query,
    options
  };
}

export function useGetActivity() {
  const nowStartMonth = dayjs(now).utc().startOf('month');
  const { activeSpace } = useAuth();

  const query = useQuery(ALL_ACTIVITY_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      date: nowStartMonth.toISOString(),
      spaceId: activeSpace?.space?.id as string
    }
  });

  const activity = useMemo(() => {
    if (!query?.data?.space) return [];
    return query?.data?.space?.subscriptions?.reduce<Array<AreaChartItemProps>>(
      (acc: AreaChartItemProps[], credit) => {
        const createdAt = dayjs(credit.createdAt).format('YYYY-MM-DD');
        const findIndex = acc.findIndex((item) => item.time === createdAt);

        if (findIndex !== -1) {
          acc[findIndex].value += 1;
        } else {
          acc.push({
            value: 1,
            time: createdAt,
            date: credit.createdAt
          });
        }
        return acc;
      },
      []
    );
  }, [query?.data]);

  return {
    ...query,
    activity,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}

type CreditsType = DeepNonNullable<GetUpcomingRenewalsQuery>;

export function useGetUpcomingRenewals() {
  // const nowStartMonth = dayjs(now).utc().startOf('month');
  const { activeSpace } = useAuth();

  const queryValid = useQuery(GET_VALID_RENEWALS_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });

  const query = useQuery(GET_UPCOMING_RENEWALS_QUERY, {
    skip: !activeSpace?.space?.id,
    variables: {
      spaceId: activeSpace?.space?.id as string
    }
  });

  const renewals = useMemo(() => {
    if (!query?.data?.space) return [];
    return query?.data?.space?.credits?.reduce<CreditsType['space']['credits']>(
      (acc, credit) => {
        const validIds =
          queryValid?.data?.space?.credits?.map?.((i) => i?.user?.id) || [];

        if (!validIds.includes((credit.user as UserFragmentFragment)?.id)) {
          return acc;
        }

        if (dayjs(credit?.expiredAt).diff(now, 'day') > 15) {
          return acc;
        }

        const findIndex = acc?.findIndex?.(
          (item) => item.user?.id === (credit.user as UserFragmentFragment)?.id
        );

        if (findIndex === -1) {
          acc.push(credit as CreditsType['space']['credits'][0]);
        }
        return acc;
      },
      []
    );
  }, [query?.data?.space, queryValid?.data]);

  return {
    ...query,
    renewals,
    fetching: query?.loading,
    loading: query?.loading && !query?.data
  };
}
