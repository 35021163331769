import { EyeIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Avatar, Tooltip } from '@nextui-org/react';

import Text from '@/lib/ui/text';
import ShortId from '@/lib/ui/short-id';
import { useTranslation } from '@/hooks/useTranslation';
import { TableColumn } from '@/lib/ui/table.tsx';
import { CLIENT_ROUTES } from '@/app/app/clients/common/routes.ts';
import { UserFragmentFragment } from '@/__generated__/graphql.ts';
import StatusColor from '@/lib/ui/status-color.tsx';
import { CLIENT_STATUS_COLOR } from '@/app/app/clients/common/colors/status.colors.ts';

export const useColumnSettings = (): TableColumn<UserFragmentFragment> => {
  const { t } = useTranslation();

  return [
    {
      field: 'id',
      headerName: 'ID',
      renderCell({ row }) {
        return <ShortId>{row.id}</ShortId>;
      }
    },
    {
      field: 'email',
      headerName: t('form.email')
    },
    {
      field: 'avatar',
      headerName: t('form.avatar'),
      renderCell({ row }) {
        return (
          <Avatar src={row?.avatar?.url} name={row?.fullName?.toString?.()} />
        );
      }
    },
    {
      field: 'firstName',
      headerName: t('form.first_name'),
      renderCell({ row }) {
        return <Text className="capitalize">{row.firstName}</Text>;
      }
    },
    {
      field: 'lastName',
      headerName: t('form.last_name'),
      renderCell({ row }) {
        return <Text className="capitalize">{row.lastName}</Text>;
      }
    },
    {
      field: 'status',
      headerName: t('status'),
      renderCell({ row }) {
        return (
          <StatusColor
            severity={CLIENT_STATUS_COLOR[row?.state as string]}
            label={t(
              row?.state?.toLowerCase() as LanguageKeys,
              row?.state?.toString?.()
            )}
          />
        );
      }
    },
    {
      field: 'actions',
      headerName: t('actions'),
      renderCell({ row }) {
        return (
          <Tooltip content={t('view_more')}>
            <span>
              <Link to={CLIENT_ROUTES.CLIENT_DETAILS(row.id)}>
                <EyeIcon className="h-4 w-4" />
              </Link>
            </span>
          </Tooltip>
          // <Button variant="light" className="h-8 w-8 p-0 text-current">
          //
          // </Button>
          // <Dropdown>
          //   <DropdownTrigger>
          //     <Button variant="light" className="h-8 w-8 p-0 text-current">
          //       <MoreVertical className="h-4 w-4" />
          //     </Button>
          //   </DropdownTrigger>
          //   <DropdownMenu>
          //     <DropdownSection title={t('actions')} showDivider>
          //       <DropdownItem key="view-more">
          //
          //       </DropdownItem>
          //     </DropdownSection>
          //   </DropdownMenu>
          // </Dropdown>
        );
      }
    }
  ];
};
