import { gql } from '@/__generated__';

export const TODAY_SESSIONS_QUERY = gql(`
  query TodaySessions($spaceId: ID!, $day: String!) {
    sessionsListBackoffice(spaceId: $spaceId, day: $day, time: DAY) {
      ...SessionBoardFragment
    }
  }
`);

export const TOTAL_CUSTOMER_QUERY = gql(`
  query TotalCustomer($spaceId: ID!) {
    space(where: { id: $spaceId }) {
      id
      userRegisteredCount
    } 
  }
`);

export const TOTAL_REVENUE_QUERY = gql(`
  query TotalRevenue($spaceId: ID!, $date: DateTime!) {
    credits (where: { space: { id: { equals: $spaceId } }, createdAt: { gte: $date } }) {
      id
      totalPrice
      paymentMethod
    }  
  }
`);

export const ALL_ACTIVITY_QUERY = gql(`
  query AllActivityQuery($spaceId: ID!, $date: DateTime!) {
    space(where: { id: $spaceId }) {
      id
      subscriptions(where: { createdAt: { gte: $date } }) {
        id
        createdAt
      }
    }  
  }
`);

export const GET_VALID_RENEWALS_QUERY = gql(`
  query GetValidRenewals($spaceId: ID!) {
    space(where: { id: $spaceId }) {
      id
      credits(
        orderBy: [{ createdAt: desc }],
        where: {
          status: { equals: "valid" }
        },
      ) {
        id
        expiredAt
        user {
          id
        }
      }
    }  
  }
`);

export const GET_UPCOMING_RENEWALS_QUERY = gql(`
  query GetUpcomingRenewals($spaceId: ID!) {
    space(where: { id: $spaceId }) {
      id
      credits(
        orderBy: [{ createdAt: desc }],
        where: {
          status: { not: { equals: "valid" } }
        },
        take: 20
      ) {
        id
        status
        updatedAt
        creditBuy
        expiredAt
        plan {
          id
          name
        }
        user {
          ...UserFragment
        }
      }
    }  
  }
`);
